import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../shared/app-config';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {


  constructor(private http: HttpClient) { }

  getNotifications(userId, page = 0, size = 100) {
    return this.http.get<any>(`${AppConfig.NOTIFICATIONS_URL}offline-notifications/${userId}`);
  }

  getNotificationsCount(userId) {
    return this.http.get<any>(`${AppConfig.NOTIFICATIONS_URL}offline-notifications/count/${userId}`);
  }

  makeNotificatioAsRead(userId, ids) {
    return this.http.put<any>(`${AppConfig.NOTIFICATIONS_URL}offline-notifications/${userId}`, ids);
  }
}
