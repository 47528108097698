import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProgressDonutData } from './progress-donut-data';

@Component({
  selector: 'app-progress-donut',
  templateUrl: './progress-donut.component.html',
  styleUrls: ['./progress-donut.component.scss'],
})
export class ProgressDonutComponent implements OnInit, OnChanges {

  @Input() data: ProgressDonutData;

  donutImage;

  constructor() { }

  ngOnInit() {
    if (this.data) {
      this.donutImage = `url("assets/img/progress-donut-${this.data.value}.svg")`; 
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {
     if (this.data) {
      this.donutImage = `url("assets/img/progress-donut-${this.data.value}.svg")`; 
    }
  }
}
