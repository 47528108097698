import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProgressDonutComponent } from './progress-donut.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [ProgressDonutComponent],
  exports: [ProgressDonutComponent]
})
export class ProgressDonutComponentModule {}
